<template>
	<div>
		<hangarCom title="客户服务" :buttonList="buttonList" @curBtn="changeCurrent"></hangarCom>
		<div class="contactUS_page flex_column">
			<div class="center flex_1">
				<div class="text_t">{{ current == 0 ? '全球寻找意向飞机服务' : '请填写真实个人信息' }}</div>
				<div class="text_c" v-if="current == 0">请填写真实个人信息</div>
				<div class="name_input flex_row">
					<div class="flex_2">意向飞机:</div>
					<div class="flex_8"><el-input v-model="input" size="mini" placeholder="请输入品牌及型号"></el-input></div>
				</div>
				<div class="name_input flex_row">
					<div class="flex_2">联系人:</div>
					<div class="flex_8"><el-input v-model="input" size="mini" placeholder="请输入联系人"></el-input></div>
				</div>
				<div class="name_input flex_row">
					<div class="flex_2">手机号码:</div>
					<div class="flex_8"><el-input v-model="input" size="mini" placeholder="请输入手机号码"></el-input></div>
				</div>
				<div class="name_input flex_row">
					<div class="flex_2">航程:</div>
					<div class="flex_8"><el-input v-model="input" size="mini" placeholder="请选择意向飞机飞行航程"></el-input></div>
				</div>
				<div class="name_input flex_row">
					<div class="flex_2">座位数:</div>
					<div class="flex_8"><el-input v-model="input" size="mini" placeholder="请选择意向座位数"></el-input></div>
				</div>
				<div class="name_input flex_row">
					<div class="flex_2">机龄:</div>
					<div class="flex_8"><el-input v-model="input" size="mini" placeholder="请选择意向机龄"></el-input></div>
				</div>
				<div class="name_input flex_row">
					<div class="flex_2">价格:</div>
					<div class="flex_8"><el-input v-model="input" size="mini" placeholder="请输入意向价格"></el-input></div>
				</div>
				<div class="name_input flex_row"></div>
				<!-- 文件上传 -->
				<div class="upload" v-if="current != 0">
					<el-upload action="https://jsonplaceholder.typicode.com/posts/" list-type="picture-card" :on-preview="handlePictureCardPreview" :on-remove="handleRemove">
						<i class="el-icon-plus"></i>
					</el-upload>
					<el-dialog :visible.sync="dialogVisible"><img width="100%" :src="dialogImageUrl" alt="" /></el-dialog>
				</div>
				<!-- 提交 -->
				<div class="tijiao"><el-button size="mini" type="warning" plain>立即提交</el-button></div>
			</div>
		</div>
	</div>
</template>

<script>
import hangarCom from '../components/hangarCom';
export default {
	data() {
		return {
			input: '',
			textarea: '',
			current: 0,
			dialogImageUrl: '',
			dialogVisible: false,
			buttonList: [
				{
					name: '买飞机',
					id: '0'
				},
				{
					name: '卖飞机',
					id: '1'
				},
				{
					name: '飞机托管',
					id: '2'
				}
			]
		};
	},
	methods: {
		changeCurrent(id) {
			this.current = id;
			// console.log(this.current);
		},
		handleRemove(file, fileList) {
			console.log(file, fileList);
		},
		handlePictureCardPreview(file) {
			this.dialogImageUrl = file.url;
			this.dialogVisible = false;
		}
	},
	components: {
		hangarCom
	}
};
</script>

<style lang="scss" scoped>
@import '../assets/scss/config.scss';
@import '../assets/scss/mixin.scss';
.contactUS_page {
	height: 664px;
	font-weight: 400;

	background: url('../assets/image/14.jpg') no-repeat center;
	background-size: 100% 100%;
	font-size: 16px;
	.center {
		margin: 10px 0;
		background-color: $colorG;
		overflow: hidden;
		overflow-y: scroll;
		max-width: 406px;
		box-sizing: border-box;
		padding: 30px 10px 0px 10px;
		text-align: center;
		.center::-webkit-scrollbar {
			display: none;
		}
		.text_c {
			font-size: 12px;
			margin-top: 12px;
			line-height: 20px;
			color: #7d7d7d;
		}
		.text_t {
			color: #000000;
			font-size: 20px;
			font-weight: 400;
		}
		.name_input {
			font-size: 14px;
			font-weight: 400;
			margin-top: 20px;
			justify-content: flex-start;
			.flex_2 {
				text-align: right;
			}
			.el-input {
				width: 90%;
				margin-left: 10px;
				justify-content: flex-end;
			}
		}
		.textarea {
			margin-top: 10px;
			height: 200px;
		}
		.tijiao {
			margin: 10px auto;
		}
	}
}
</style>
